@import '../partials';

.interior-banner {
  $c: &;

  &--has-nav {
    #{$c}__copy-wrapper {
      padding-bottom: rem(80);

      @include bp(lg) {
        padding-bottom: 0;
      }
    }
  }

  &__boundary {
    position: relative;
  }

  &__image {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: rem(480);

    @include bp(sm) {
      height: rem(600);
    }

    &::before {
      content: " ";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      @include overlay-gradient;
    }

    picture {
      width: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      min-height: 100%;
    }
  }

  &__copy-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__copy {
    text-align: left;
    font-family: $sans-serif-font;
    color: $white;
    padding: rem(34) 0;
    max-width: rem(1200);
    width: 100%;
    margin: 0 auto;

    @include bp(lg) {
      padding-right: rem(118); // dimensions specific to design
    }
  }

  &__title {
    font-size: rem(40);
    line-height: 0.85;
    font-weight: $font-weight-heavy;
    text-transform: uppercase;
    margin: 0;

    @include bp(sm) {
      font-size: rem(64);
      line-height: 0.91;
    }

    @include bp(lg) {
      $interior-gutter: rem(378);
      width: calc(100% - #{$interior-gutter});
      font-size: rem(72);
      line-height: 1;
    }
  }

  &__text {
    font-size: rem(22);
    font-weight: $font-weight-heavy;
    line-height: 1.64;
    max-width: rem(700);
    margin: 0;
    text-transform: uppercase;
    letter-spacing: rem(3.9);

    @include bp(sm) {
      letter-spacing: rem(10);
    }
  }
}